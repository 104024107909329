import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';

const Place = ({ data }) => {
  const content = data.nodePlace;

  const image = {
    src:
      content.relationships.field_image &&
      content.relationships.field_image.max_1600_10_7
        ? content.relationships.field_image.max_1600_10_7.childImageSharp
          .resolutions.src
        : null,
    alt:
      content.field_image ? content.field_image.alt
        : null
  };

  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        siteConfig={data.site.siteMetadata}
        pageUrl={content.path.alias}
        image={image}
      />
      <ContentDisplay {...content} imageLocation="sidebar" />
    </DefaultLayout>
  );
};

Place.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query PlaceQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    nodePlace(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
        summary
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      field_image {
        alt
      }
      relationships {
        field_image {
          max_1600_10_7 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              resolutions(width: 470, height: 330, quality: 90) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
          max_1600_16_9 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              sizes(maxWidth: 992, maxHeight: 558) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`;

export default Place;
